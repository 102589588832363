<template>
  <f7-page>
    <f7-navbar title="信秒贷" back-link="  " sliding>
      <f7-nav-right>
        <f7-link icon="icon-bars" open-panel="left"></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <template #fixed>

    <!-- FAQ Right Bottom -->
    <f7-fab position="right-bottom" color="pink" text="Q&A" href="/FAQ/">
    </f7-fab>
  </template>

    <div class="bg">

      <!-- 上部背景图片 -->
      <div class="top-image-bg">
        <p><img class="imgBox-bg" src="../assets/bank/citic/bg.png" /></p>
      </div>

      <!-- 活动时间 -->
      <div class="product-feature">
        <p><f7-chip outline text="活动时间"></f7-chip></p>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5"></f7-col>
          <f7-col width="95" style="font-size: 14px;">2022年3月1日——3月25日</f7-col>
        </f7-row>
      </div>

      <!-- 产品特点 -->
      <div class="product-feature">
        <p><f7-chip outline text="产品特点"></f7-chip></p>
        <f7-row no-gap style="padding-top: 5px; font-size: 14px;">
          <f7-col width="5"></f7-col>
          <f7-col width="95">审批<span class="text-part-red">快</span>：线上自助申请，一键查额，<span class="text-part-red">秒到账</span></f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px; font-size: 14px;">
          <f7-col width="5"></f7-col>
          <f7-col width="95">额度<span class="text-part-red">高</span>：纯信用，线上最高可达<span class="text-part-red">20万元</span>，线下最高可达<span class="text-part-red">30万元</span></f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px; font-size: 14px;">
          <f7-col width="5"></f7-col>
          <f7-col width="95">期限<span class="text-part-red">长</span>：最长<span class="text-part-red">3</span>年</f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px; font-size: 14px;">
          <f7-col width="5"></f7-col>
          <f7-col width="95">还款<span class="text-part-red">易</span>：按日计息、<span class="text-part-red">随借随还</span></f7-col>
        </f7-row>
      </div>

      <!-- 申请流程 -->
      <div class="product-feature">
        <p><f7-chip outline text="申请流程"></f7-chip></p>
        <div class="timeline medium-sides">
            <div class="timeline-item">
              <div class="timeline-item-divider"></div>
              <div class="timeline-item-content">
                <div class="timeline-item-inner">
                  <div class="timeline-item-time">步骤一</div>
                  <div class="timeline-item-text">点击下方“立即申请”按钮</div>
                </div>
              </div>
            </div>
            <div class="timeline-item">
              <div class="timeline-item-divider"></div>
              <div class="timeline-item-content">
                <div class="timeline-item-inner">
                  <div class="timeline-item-time">步骤二</div>
                  <div class="timeline-item-text">在线填写信息</div>
                </div>
              </div>
            </div>
            <div class="timeline-item">
              <div class="timeline-item-divider"></div>
              <div class="timeline-item-content">
                <div class="timeline-item-inner">
                  <div class="timeline-item-time">步骤三</div>
                  <div class="timeline-item-text">完成身份认证</div>
                </div>
              </div>
            </div>
            <div class="timeline-item">
              <div class="timeline-item-divider"></div>
              <div class="timeline-item-content">
                <div class="timeline-item-inner">
                  <div class="timeline-item-time">步骤四</div>
                  <div class="timeline-item-text">2分钟审批额度</div>
                </div>
              </div>
            </div>
            <div class="timeline-item">
              <div class="timeline-item-divider"></div>
              <div class="timeline-item-content">
                <div class="timeline-item-inner">
                  <div class="timeline-item-time">步骤五</div>
                  <div class="timeline-item-text">额度协议签约</div>
                </div>
              </div>
            </div>
            <div class="timeline-item">
              <div class="timeline-item-divider"></div>
              <div class="timeline-item-content">
                <div class="timeline-item-inner">
                  <div class="timeline-item-time">步骤六</div>
                  <div class="timeline-item-text"><span style="font-weight: bold;">T+1工作日提款</span></div>
                </div>
              </div>
            </div>
        </div>
      </div>


      <!-- 优惠活动 -->
      <div class="product-feature">
        <p><f7-chip outline text="优惠活动"></f7-chip></p>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5">♦</f7-col>
          <f7-col width="95" style="font-size: 14px;"><span style="font-weight: bold;">满足条件：</span>受邀企业客户签约信秒贷，且2022年2月28日无信秒贷余额。</f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5">♦</f7-col>
          <f7-col width="95" style="font-size: 14px;"><span style="font-weight: bold;">福袋优惠：</span>获利息券福袋1个<span class="text-part-red">（30天签约有效期，12个月提款有效期）</span>。</f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5"></f7-col>
          <f7-col width="95">福袋包含：</f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap>
              <f7-col width="5">➀</f7-col>
              <f7-col width="95">3.85%票面券1张，自发放日起7天内提款使用有效</f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap>
              <f7-col width="5">➁</f7-col>
              <f7-col width="95">9.5 折折扣券1张，自发放日起 90天内提款使用有效</f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5"></f7-col>
          <f7-col width="95">
            <f7-row no-gap>
              <f7-col width="5">➂</f7-col>
              <f7-col width="95">9.8 折折扣券1张，自发放日起 120天内提款使用有效</f7-col>
            </f7-row>
          </f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5">♦</f7-col>
          <f7-col width="95" style="font-size: 14px;"><span style="font-weight: bold;">福袋发放时间：</span>客户签约日起<span class="text-part-red">1-2个工作日</span>内完成福袋发放福袋数量有限， 先到先得。</f7-col>
        </f7-row>
      </div>


      <!-- 合规提示 -->
      <div class="product-feature">
        <p><f7-chip outline text="合规提示"></f7-chip></p>
        <f7-row no-gap style="padding-top: 5px;">
          <f7-col width="5"></f7-col>
          <f7-col width="95" style="font-size: 14px;">应确保合法合规使用贷款资金，<span class="text-part-red">信秒贷严禁用于购房、理财、股票等风险投资及其他违规领域</span>。贷款有风险，借款需谨慎，请根据个人能力合理贷款。若发生贷款用途违规，我行会依据贷款合同条款约定，对违规贷款执行清收。</f7-col>
        </f7-row>
      </div>

      <!-- 注意事项 -->
      <div class="product-feature">
        <p><f7-chip outline text="注意事项"></f7-chip></p>
        <f7-row no-gap style="padding-top: 5px; font-size: 14px;">
          <f7-col width="5">♦</f7-col>
          <f7-col width="95"><span class="text-part-red">线上</span>申请额度，<span class="text-part-red">线下</span>当场办卡。</f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px; font-size: 14px;">
          <f7-col width="5">♦</f7-col>
          <f7-col width="95">发放利率优惠券需满足同时<span class="text-part-red">办理储蓄卡和信用卡</span>。</f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px; font-size: 14px;">
          <f7-col width="5">♦</f7-col>
          <f7-col width="95"><span class="text-part-red">利息券发放后方可提款</span>。</f7-col>
        </f7-row>
        <f7-row no-gap style="padding-top: 5px; font-size: 14px;">
          <f7-col width="5">♦</f7-col>
          <f7-col width="95">如有疑问，可联系客户经理：刘经理，联系电话：13761699747。</f7-col>
        </f7-row>
      </div>

      <!-- 底部按钮 -->
      <div class="buttonSublit btnAnimation">
        <a onclick="_hmt.push(['_trackEvent', 'citicBank_external_link_apply_loan', 'click'])">
          <f7-button fill round large href='https://ecitich5.urthink.com/s/1ZboKL3q?yj_id=1789_eciticf3f7402d2dfd6dc2&t=1645414591&org_code=731331&recommend=111109' external>立即申请</f7-button>
        </a>
      </div>

    </div>
  </f7-page>
</template>

<script>
export default {

}
</script>


<style lang="less" scoped>
.bg {
  background: white;
  left:0%;
  right:0%;
  top:0%;
  // height: 100%;
  bottom: 0%;
  padding: 15px;
}

.top-image-bg {
  >p{
  margin: 0;
  width: 100%;
}
}
.imgBox-bg {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.product-feature {
  padding-top: 10px;
  padding-left: 7%;
  padding-right: 7%;
  font-size: 12px;
  >p {
    margin: 0;
  }
}
.text-part-red {
  color: rgb(185, 46, 55);
  // font-size: 14px;
  font-weight: bold;
}

.timeline-item-inner {
  --f7-timeline-item-inner-bg-color: #F0EFF6;
}
.timeline {
  --f7-timeline-margin-vertical: 0;
}
.fab-right-bottom {
  bottom: 100px;
  right: 15px;
}

.fab-extended {
  --f7-fab-extended-size: 34px;
}
.color-pink {
  --f7-theme-color: #CA1019;
}
.chip {
  --f7-chip-text-color: #BE0017;
  --f7-chip-font-size: 15px;
  --f7-chip-font-weight: bold;
}
.chip-outline {
  --f7-chip-outline-border-color: #BE0017;
  --f7-chip-text-color: #BE0017;
}
.button-fill {
  background: -prefix-linear-gradient(left, rgb(200,25,46), #FFB6C1); 
  background: linear-gradient(to right, rgb(200,25,46), #FFB6C1);
}
.button-large {
  --f7-button-large-font-size: 25px;
}
</style>